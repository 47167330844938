import { Injectable } from '@angular/core';
import { IExchange } from '../../model/entities.model';
import { IGetExchangesResponse } from '../../model/responses.model';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {
  protected exchanges: IExchange[] = [];

  constructor(private apiService: ApiService) {
    this.readExchanges();
  }

  protected readExchanges(): void {
    this.apiService.getExchanges().subscribe((response: IGetExchangesResponse) => {
      this.exchanges = response.exchanges;
    });
  }

  public getExchange(code: string): number {
    const latestRates = this.exchanges.filter(e => e.code === code);
    if (latestRates.length !== 1) {
      return 0;
    }
    return latestRates[0].value;
  }
}
