import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUser } from '../../model/entities.model';
import { AuthGuardService } from '../../services/auth-guard/auth-guard.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  isMenuCollapsed = true;
  user: IUser;
  protected userSubscription: Subscription;

  constructor(protected authGuard: AuthGuardService) {}

  ngOnInit(): void {
    this.userSubscription = this.authGuard.getUser().subscribe((user: IUser) => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
