import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUser } from '../../model/entities.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  protected loginDestinationUrl: string;
  protected user: IUser = null;
  protected userBehaviorSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(this.user);

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.initialize();
  }

  initialize(): void {
    if (isPlatformBrowser(this.platformId)) {
      let userString: string = null;
      userString = localStorage.getItem('user');
      if (userString) {
        this.user = JSON.parse(userString);
        this.userBehaviorSubject.next(this.user);
      }
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.isAuthenticated()) {
      this.activatedRoute.params.subscribe(params => {
        this.loginDestinationUrl = state.url;
        this.router.navigateByUrl('/login');
      });
      return false;
    }
    return true;
  }

  getAuthToken(): string {
    let authTokenString: string = null;
    if (isPlatformBrowser(this.platformId)) {
      authTokenString = localStorage.getItem('authToken');
    }
    return authTokenString;
  }

  isAuthenticated(): boolean {
    return this.getAuthToken() !== null;
  }

  logout(): void {
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    this.userBehaviorSubject.next(null);
  }

  login(user: IUser, authToken: string): void {
    localStorage.setItem('authToken', authToken);
    localStorage.setItem('user', JSON.stringify(user));
    if (this.loginDestinationUrl) {
      this.router.navigateByUrl(this.loginDestinationUrl);
    } else {
      this.router.navigateByUrl('/');
    }
    this.userBehaviorSubject.next(user);
  }

  public getUser(): Observable<IUser> {
    return this.userBehaviorSubject.asObservable();
  }
}
