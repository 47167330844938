<header>
  <nav class="navbar navbar-dark navbar-expand-md bg-dark fixed-top">
    <a class="navbar-brand" routerLink="/">IWX Media</a>
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto">
        <li *ngIf="user" class="nav-item"><a class="nav-link" (click)="isMenuCollapsed = true;" routerLink="/advertisements">Advertisements</a></li>
        <li *ngIf="user" class="nav-item"><a class="nav-link" (click)="isMenuCollapsed = true;" routerLink="/stats">Stats</a></li>
        <li *ngIf="user && user.isAdmin" class="nav-item"><a class="nav-link" (click)="isMenuCollapsed = true;" routerLink="/admin">Admin</a></li>
        <li *ngIf="user" class="nav-item"><a class="nav-link" (click)="isMenuCollapsed = true;" routerLink="/dashboard">Dashboard</a></li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li *ngIf="!user" class="nav-item"><a class="nav-link" (click)="isMenuCollapsed = true;" routerLink="/sign-up">Sign up</a></li>
        <li *ngIf="!user" class="nav-item"><a class="nav-link" (click)="isMenuCollapsed = true;" routerLink="/login">Login</a></li>
        <li *ngIf="user" class="nav-item" ngbDropdown>
          <a class="nav-link" tabindex="0" ngbDropdownToggle id="userDropdown" role="button">
            {{user.companyName}}
          </a>
          <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu-right">
            <a ngbDropdownItem (click)="isMenuCollapsed = true;" routerLink="/user">Modify user</a>
            <a ngbDropdownItem (click)="isMenuCollapsed = true;" routerLink="/change-password">Change password</a>
            <a ngbDropdownItem (click)="isMenuCollapsed = true;" routerLink="/logout">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
