import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(array: any, field: string, descendant?: boolean): any[] {
    if (descendant === undefined) {
      descendant = false;
    }

    if (!Array.isArray(array)) {
      return;
    }

    array.sort((item1: any, item2: any) => {
      if (this.getItemValue(item1, field) < this.getItemValue(item2, field)) {
        return descendant ? 1 : -1;
      } else if (this.getItemValue(item1, field) > this.getItemValue(item2, field)) {
        return descendant ? -1 : 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  private getItemValue(item: any, field: string): any {
    const fields = field.split('.');
    let value = item;
    for (const currentField of fields) {
      value = value[currentField];
    }
    return value ?? '';
  }
}
