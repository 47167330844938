import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { AuthGuardService } from '../auth-guard/auth-guard.service';

@Injectable({
  providedIn: 'root',
})
export class ApiBaseService {
  private API_URL: string;

  constructor(protected http: HttpClient,
              @Inject(PLATFORM_ID) protected platformId: object,
              protected authGuard: AuthGuardService,
              protected state: TransferState) {
    this.API_URL = environment.backendUrl + '/api';
  }

  protected callApi(relativeUrl: string, request: any, canBeCached: boolean = false): Observable<object> {
    if (environment.useMock) {
      return this.callMockApi(relativeUrl, request, canBeCached);
    }

    if (!canBeCached) {
      return this.http.post(this.API_URL + relativeUrl, request);
    }

    const stateKey = makeStateKey(relativeUrl);

    if (isPlatformServer(this.platformId)) {
      return new Observable<object>((observer) => {
        this.http.post(this.API_URL + relativeUrl, request).subscribe((response: any) => {
          // Add to state
          this.state.set(stateKey, response);
          observer.next(response);
        });
      });
    } else {
      // Get from the state
      const stateResponse = this.state.get<Object>(stateKey, undefined);
      // If not in the state
      if (stateResponse === undefined) {
        const httpResponse =  this.http.post(this.API_URL + relativeUrl, request);

        return this.http.post(this.API_URL + relativeUrl, request);
      } else {
        // Remove from the state
        this.state.remove(stateKey);
        // Return the state value
        return new Observable<object>((observer) => {
          observer.next(stateResponse);
        });
      }
    }
  }

  public getErrorCode(response: any): string {
    if (response.error) {
      if (response.error.error) {
        return response.error.error.code;
      } else {
        return response.error.code;
      }
    }
    return null;
  }

  private callMockApi(relativeUrl: string, request: any, canBeCached: boolean = false): Observable<object> {
    return this.http.get(this.API_URL + relativeUrl + '.json');
  }
}
