import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { IGetContentRequest } from '../../model/requests.model';
import { IGetContentResponse } from '../../model/responses.model';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  contentText: string;
  @Input() contentId: string;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    const request: IGetContentRequest = {
      contentId: this.contentId
    };
    this.apiService.getContent(request).subscribe((response: IGetContentResponse) => {
      if (response.succeeded) {
        this.contentText = response.content.contentText;
      }
    });
  }

}
