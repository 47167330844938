import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortEstimated'
})
export class SortEstimatedPipe implements PipeTransform {
  transform(array: any): any[] {
    array.sort((item1: any, item2: any) => {
      return item1.enabled && !item2.enabled ? -1 : 1;
    });
    array.sort((item1: any, item2: any) => {
      if (!item1.estimationDate) {
        return 1;
      }
      if (!item2.estimationDate) {
        return -1;
      }
      if (item1.estimationDate < item2.estimationDate) {
        return -1;
      } else {
        return 1;
      }
    });
    return array;
  }
}
