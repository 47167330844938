import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[focus]'
})
export class FocusDirective implements AfterContentInit {
  constructor(private elementRef: ElementRef) {
  }

  ngAfterContentInit(): void {
    this.elementRef.nativeElement.focus();
  }
}
