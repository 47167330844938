import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyNameFilter'
})
export class CompanyNameFilterPipe implements PipeTransform {
  transform(items: any, companyName: string): any {
    if (!companyName) {
      return items;
    }

    items = items.filter(a => a.user?.companyName === companyName);

    return items;
  }
}
