import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import {
  IGetContentRequest, IModifyUserRequest, IAddUserRequest,
  ILoginRequest, IGetAdvertisementsRequest, IEnableAdvertisementRequest,
  IDisableAdvertisementRequest,
  IChangePasswordRequest,
  IForgotPasswordRequest,
  IAddAdvertisementRequest,
  IModifyAdvertisementRequest,
  IDeleteAdvertisementRequest,
  IGetAdvertisementRequest,
  IGetStatsRequest,
  IAddPaymentRequest,
  IDeletePaymentRequest,
  IGetPaymentRequest,
  IGetPaymentsRequest,
  IGetPublisherRequest,
  IGetPublishersRequest,
  IModifyPaymentRequest,
  IModifyPublisherRequest,
  ITogglePublisherRequest,
  IGetDashboardRequest
} from '../../model/requests.model';
import {
  IGetUserResponse, IGetContentResponse, IModifyUserResponse,
  IAddUserResponse as IAddUserResponse, ILoginResponse,
  IGetAdvertisementsResponse, IEnableAdvertisementResponse,
  IDisableAdvertisementResponse,
  IGetZonesResponse, IChangePasswordResponse, IForgotPasswordResponse,
  IAddAdvertisementResponse, IModifyAdvertisementResponse, IDeleteAdvertisementResponse, IGetCountriesResponse, IGetAdvertisementResponse, IGetStatsResponse, IGetCompanyNamesResponse, IAddPaymentResponse, IDeletePaymentResponse, IGetPaymentResponse, IGetPaymentsResponse, IGetPublisherResponse, IGetPublishersResponse, IModifyPaymentResponse, IModifyPublisherResponse, ITogglePublisherResponse, IGetDashboardResponse, IGetExchangesResponse
} from '../../model/responses.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends ApiBaseService {
  /* Site */
  getContent(request: IGetContentRequest): Observable<IGetContentResponse> {
    return this.callApi('/site/GetContent', request) as Observable<IGetContentResponse>;
  }

  /* User */
  login(request: ILoginRequest): Observable<ILoginResponse> {
    return this.callApi('/user/Login', request) as Observable<ILoginResponse>;
  }

  addUser(request: IAddUserRequest): Observable<IAddUserResponse> {
    return this.callApi('/user/AddUser', request) as Observable<IAddUserResponse>;
  }

  modifyUser(request: IModifyUserRequest): Observable<IModifyUserResponse> {
    return this.callApi('/user/ModifyUser', request) as Observable<IModifyUserResponse>;
  }

  getUser(): Observable<IGetUserResponse> {
    return this.callApi('/user/GetUser', {}) as Observable<IGetUserResponse>;
  }

  changePassword(request: IChangePasswordRequest): Observable<IChangePasswordResponse> {
    return this.callApi('/user/ChangePassword', request) as Observable<IChangePasswordResponse>;
  }

  forgotPassword(request: IForgotPasswordRequest): Observable<IForgotPasswordResponse> {
    return this.callApi('/user/ForgotPassword', request) as Observable<IForgotPasswordResponse>;
  }

	getDashboard(request: IGetDashboardRequest): Observable<IGetDashboardResponse> {
		return this.callApi('/user/GetDashboard', request) as Observable<IGetDashboardResponse>;
	}

	getExchanges(): Observable<IGetExchangesResponse> {
		return this.callApi('/user/GetExchanges', {}) as Observable<IGetExchangesResponse>;
	}

  /* Advertisements */
  getAdvertisements(request: IGetAdvertisementsRequest): Observable<IGetAdvertisementsResponse> {
    return this.callApi('/advertisement/GetAdvertisements', request) as Observable<IGetAdvertisementsResponse>;
  }

  enableAdvertisement(request: IEnableAdvertisementRequest): Observable<IEnableAdvertisementResponse> {
    return this.callApi('/advertisement/EnableAdvertisement', request) as Observable<IEnableAdvertisementResponse>;
  }

  disableAdvertisement(request: IDisableAdvertisementRequest): Observable<IDisableAdvertisementResponse> {
    return this.callApi('/advertisement/DisableAdvertisement', request) as Observable<IDisableAdvertisementResponse>;
  }

  getZones(): Observable<IGetZonesResponse> {
    return this.callApi('/advertisement/GetZones', {}) as Observable<IGetZonesResponse>;
  }

  getCompanyNames(): Observable<IGetCompanyNamesResponse> {
    return this.callApi('/advertisement/GetCompanyNames', {}) as Observable<IGetCompanyNamesResponse>;
  }

  getCountries(): Observable<IGetCountriesResponse> {
    return this.callApi('/advertisement/GetCountries', {}) as Observable<IGetCountriesResponse>;
  }

  addAdvertisement(request: IAddAdvertisementRequest): Observable<IAddAdvertisementResponse> {
    return this.callApi('/advertisement/AddAdvertisement', request) as Observable<IAddAdvertisementResponse>;
  }

  modifyAdvertisement(request: IModifyAdvertisementRequest): Observable<IModifyAdvertisementResponse> {
    return this.callApi('/advertisement/ModifyAdvertisement', request) as Observable<IModifyAdvertisementResponse>;
  }

  deleteAdvertisement(request: IDeleteAdvertisementRequest): Observable<IDeleteAdvertisementResponse> {
    return this.callApi('/advertisement/DeleteAdvertisement', request) as Observable<IDeleteAdvertisementResponse>;
  }

  getAdvertisement(request: IGetAdvertisementRequest): Observable<IGetAdvertisementResponse> {
    return this.callApi('/advertisement/GetAdvertisement', request) as Observable<IGetAdvertisementResponse>;
  }

  getStats(request: IGetStatsRequest): Observable<IGetStatsResponse> {
    return this.callApi('/advertisement/GetStats', request) as Observable<IGetStatsResponse>;
  }

  addPayment(request: IAddPaymentRequest): Observable<IAddPaymentResponse> {
		return this.callApi('/admin/AddPayment', request) as Observable<IAddPaymentResponse>;
	}

	deletePayment(request: IDeletePaymentRequest): Observable<IDeletePaymentResponse> {
		return this.callApi('/admin/DeletePayment', request) as Observable<IDeletePaymentResponse>;
	}

	getPayment(request: IGetPaymentRequest): Observable<IGetPaymentResponse> {
		return this.callApi('/admin/GetPayment', request) as Observable<IGetPaymentResponse>;
	}

	getPayments(request: IGetPaymentsRequest): Observable<IGetPaymentsResponse> {
		return this.callApi('/admin/GetPayments', request) as Observable<IGetPaymentsResponse>;
	}

	getPublisher(request: IGetPublisherRequest): Observable<IGetPublisherResponse> {
		return this.callApi('/admin/GetPublisher', request) as Observable<IGetPublisherResponse>;
	}

	getPublishers(request: IGetPublishersRequest): Observable<IGetPublishersResponse> {
		return this.callApi('/admin/GetPublishers', request) as Observable<IGetPublishersResponse>;
	}

	modifyPayment(request: IModifyPaymentRequest): Observable<IModifyPaymentResponse> {
		return this.callApi('/admin/ModifyPayment', request) as Observable<IModifyPaymentResponse>;
	}

	modifyPublisher(request: IModifyPublisherRequest): Observable<IModifyPublisherResponse> {
		return this.callApi('/admin/ModifyPublisher', request) as Observable<IModifyPublisherResponse>;
	}

	togglePublisher(request: ITogglePublisherRequest): Observable<ITogglePublisherResponse> {
		return this.callApi('/admin/TogglePublisher', request) as Observable<ITogglePublisherResponse>;
	}
}
