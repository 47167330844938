import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any, filter: string): any {
    if (!filter) {
      return items;
    }

    const terms = filter.split(' ');

    for (const term of terms) {
      items = this.filterTerm(items, term, this);
    }

    return items;
  }

  filterTerm(items: any[], term: string, pipe: any): any {
    return items.filter((item) => {
      for (const property in item) {
        if (item[property] === null) {
          continue;
        } else if (property === '$type') {
          continue;
        }
        if (pipe.contains(item[property], term, pipe)) {
          return true;
        }
      }
      return false;
    });
  }

  contains(o: any, term: string, pipe: any): boolean {
    if (typeof o === 'string' || o instanceof String) {
      return o.toString().toLowerCase().includes(term.toLowerCase());
    }

    if (o instanceof Object) {
      for (const property in o) {
        if (o[property] === null) {
          continue;
        }
        if (pipe.contains(o[property], term, pipe)) {
          return true;
        }
      }
      return false;
    }

    return false;
  }
}
