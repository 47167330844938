import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { ApiService } from './services/api/api.service';
import { GoogleAnalyticsService } from '@modules/google-analytics/google-analytics.service';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { ExchangeService } from './services/exchange/exchange.service';

import { FileDropDirective } from './directives/file-drop/file-drop.directive';
import { FocusDirective } from './directives/focus/focus.directive';
import { SafePipe } from './pipes/safe/safe.pipe';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { CompanyNameFilterPipe } from './pipes/company-name-filter/company-name-filter.pipe';
import { SortPipe } from './pipes/sort/sort.pipe';
import { SortEstimatedPipe } from './pipes/sort-estimated/sort-estimated.pipe';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContentComponent } from './components/content/content.component';

@NgModule({
  declarations: [
    FileDropDirective,
    FocusDirective,
    SafePipe,
    FilterPipe,
    CompanyNameFilterPipe,
    SortPipe,
    SortEstimatedPipe,
    HeaderComponent,
    FooterComponent,
    ContentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger'
    }),
    ToastrModule.forRoot()
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmationPopoverModule,
    SafePipe,
    FilterPipe,
    CompanyNameFilterPipe,
    SortPipe,
    SortEstimatedPipe,
    HeaderComponent,
    FooterComponent,
    ContentComponent,
    FileDropDirective,
    FocusDirective
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        FormsModule,
        ReactiveFormsModule,
        NgbActiveModal,
        ApiService,
        AuthGuardService,
        GoogleAnalyticsService,
        ExchangeService
      ]
    };
  }
}
