import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { AuthGuardService } from './modules/shared/services/auth-guard/auth-guard.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private toastr: ToastrService,
              private authGuard: AuthGuardService
              ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isPlatformBrowser(this.platformId)) {
      let headers = req.headers;
      if (req.method !== 'GET' || environment.useMock) {
        const authToken = this.authGuard.getAuthToken();
        if (authToken) {
          headers = headers.set('Auth-Token', authToken);
        }
      }

      const clonedRequest = req.clone({ headers });

      return next.handle(clonedRequest).pipe(
        catchError((response, caught) => {
          if (response.error && typeof response.error === 'string') {
            if (response.status === 503) {
              this.showError('The server is recycling', 'Server Error');
            } else {
              this.showError(response.error, 'Server Error');
            }
          } else if (response.error && response.error.error && response.error.error.message) {
            if (response.error.error.code !== -2 && response.error.error.code !== -100) {
              this.showError(response.error.error.message, 'Server Error');
            } else {
              return next.handle(req);
            }
          } else {
            this.showError('Something went wrong', 'Server Error');
          }
          return throwError(response);
        })) as any;
    } else {
      return next.handle(req);
    }
  }

  showError(message, title): void {
    if (message.indexOf('DOCTYPE') > 0) {
      this.toastr.error('Something went wrong', 'Server Error');
    } else {
      this.toastr.error(message, title);
    }
  }
}
