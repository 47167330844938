import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

export declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private enabled = true;
  private disclaimer: boolean;
  public timer: Observable<number>;

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private router: Router) {
  }

  init(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.googleAnalyticsHeadScripts();
      const gaKey = 'ga';
      if (localStorage.getItem(gaKey) != null) {
        this.enabled = false;
      }
    } else {
      this.enabled = false;
    }

    if (this.enabled) {
      this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
        const urlKey = 'url';
        let url = event[urlKey];
        if (url !== null && url !== undefined && url !== '' && url.indexOf('null') < 0) {
          let regex = /(\/room\/.*\/password\/).*/;
          if (url.match(regex)) {
            url = url.replace(regex, '$1***');
          }
          regex = /(\/change-password\/).*/;
          if (url.match(regex)) {
            url = url.replace(regex, '$1***');
          }
          regex = /(\/cam2cam\/).*/;
          if (url.match(regex)) {
            url = url.replace(regex, '$1***');
          }
          this.config({ page_path: url });
        }
      });
    }
  }

  private googleAnalyticsHeadScripts(): void {
    const head = document.getElementsByTagName('head')[0];

    const googleAnalyticsFirstScript = document.createElement('script');
    googleAnalyticsFirstScript.async = true;
    googleAnalyticsFirstScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.google_analytics_code;

    const googleAnalyticsSecondScript = document.createElement('script');
    googleAnalyticsSecondScript.innerHTML = '    window.dataLayer = window.dataLayer || [];\n' +
      '    function gtag(){dataLayer.push(arguments);}\n' +
      '    gtag(\'js\', new Date());\n' +
      '\n' +
      '    gtag(\'config\', \'' + environment.google_analytics_code + '\');';

    head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
    head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
  }

  config(data: any): void {
    if (this.isEnabled()) {
      this.googleAnalyticsConfig(data);
    }
  }

  event(eventName: string, data: any): void {
    if (this.isEnabled()) {
      this.googleAnalyticsEvent(eventName, data);
    }
  }

  purchase(affiliation: string, item: any): void {
    if (this.isEnabled()) {
      this.googleAnalyticsPurchase(affiliation, item);
    }
  }

  set(data: any): void {
    if (this.isEnabled()) {
      this.googleAnalyticsSet(data);
    }
  }

  private isEnabled(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return this.enabled && this.getDisclaimer();
    } else {
      return false;
    }
  }

  private getDisclaimer(): boolean {
    if (this.disclaimer) {
      return this.disclaimer;
    }

    if (isPlatformBrowser(this.platformId)) {
      const disclaimerString = localStorage.getItem('disclaimer');
      if (disclaimerString) {
        this.disclaimer = JSON.parse(disclaimerString);
      } else {
        this.disclaimer = false;
      }
      return this.disclaimer;
    } else {
      return false;
    }
  }

  private googleAnalyticsConfig(data): void {
    gtag('config', environment.google_analytics_code, data);
  }

  private googleAnalyticsEvent(eventName, data): void {
    gtag('event', eventName, data);
  }

  private googleAnalyticsSet(data): void {
    gtag('set', data);
  }

  private getTransactionId(): string {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, (c) => {
      // tslint:disable-next-line: no-bitwise
      const r = Math.random() * 16 | 0;
      // tslint:disable-next-line: no-bitwise
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private googleAnalyticsPurchase(affiliation, item): void {
    gtag('event', 'purchase', {
      transaction_id: this.getTransactionId(),
      affiliation,
      value: item.price,
      currency: 'EUR',
      tax: 0,
      shipping: 0,
      items: [
        item
      ]
    });
  }
}
